:root {
  --ck-image-style-spacing: 1.5em;
  --ck-inline-image-style-spacing: calc(var(--ck-image-style-spacing) / 2);
}

/* Provides a minimal side margin for the left and right aligned images, so that the user has a visual feedback
confirming successful application of the style if image width exceeds the editor's size.
See https://github.com/ckeditor/ckeditor5/issues/9342 */
.image-style-block-align-left,
.image-style-block-align-right {
  max-width: calc(100% - var(--ck-image-style-spacing));
}

/* Allows displaying multiple floating images in the same line.
See https://github.com/ckeditor/ckeditor5/issues/9183#issuecomment-804988132 */
.image-style-align-left,
.image-style-align-right {
  clear: none;
}

.image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}

.image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}

.image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}

.image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}

.image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}

.image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}

/* Simulates margin collapsing with the preceding paragraph, which does not work for the floating elements. */
p + .image-style-align-left,
p + .image-style-align-right,
p + .image-style-side {
  margin-top: 0;
}

& .image-inline {
  &.image-style-align-left,
  &.image-style-align-right {
    margin-top: var(--ck-inline-image-style-spacing);
    margin-bottom: var(--ck-inline-image-style-spacing);
  }

  &.image-style-align-left {
    margin-right: var(--ck-inline-image-style-spacing);
  }

  &.image-style-align-right {
    margin-left: var(--ck-inline-image-style-spacing);
  }
}

li {
  & sub {
    line-height: 0px;
  }
  & sup {
    line-height: 0px;
  }
}
