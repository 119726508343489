/* open-sans-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/open-sans-v36-latin-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../assets/fonts/open-sans-v36-latin-300italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/open-sans-v36-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/open-sans-v36-latin-italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/open-sans-v36-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  src: url("../assets/fonts/open-sans-v36-latin-500italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/open-sans-v36-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url("../assets/fonts/open-sans-v36-latin-600italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/open-sans-v36-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url("../assets/fonts/open-sans-v36-latin-700italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  src: url("../assets/fonts/open-sans-v36-latin-800.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* open-sans-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  src: url("../assets/fonts/open-sans-v36-latin-800italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

body {
  margin: 0px;
  font-family: "Open Sans", "Arial", "sans-serif";
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
  overflow-wrap: break-word;
  min-width: 280px;
  --ck-z-default: 100;
  --ck-z-modal: calc(var(--ck-z-default) + 1400);
  background-color: #f2f2f2;
}

html {
  height: fill-available;
  height: -webkit-fill-available;
}

/* override busbahnbim widget */
.lyr_tpInputWrap,
.lyr_atomDtpWrap,
.lyr_atomBtnWrap {
  padding: 0 !important;
}

.hfs_customerWidgetIcon {
  margin: 0 !important;
}

.lyr_tpInputMainWrapper {
  padding-top: 0 !important;
}

.lyr_tooltipText {
  display: none !important;
}

[data-hfs-widget] {
  z-index: 0 !important;
}

p {
  margin: 0px;
}

.ck-editor__editable {
  min-height: 240px;
}

figcaption {
  text-align: center;
}

div.raw-html-embed {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Table v1 Styles */
.table-v1 {
  border-spacing: 0;
  font-size: 16px;
}

.table-v1 th {
  border-right-width: 1px;
  border-right-color: #e5e5e5;
  border-right-style: solid;

  border-bottom-width: 1px;
  border-bottom-color: #e5e5e5;
  border-bottom-style: solid;

  font-weight: 700;
  padding: 12px;
  text-align: left;
}

.table-v1 td {
  border-right-width: 1px;
  border-right-color: #e5e5e5;
  border-right-style: solid;

  border-bottom-width: 1px;
  border-bottom-color: #e5e5e5;
  border-bottom-style: solid;

  padding: 12px;
  text-align: left;
}

/* V1 Table Header Border Bottom for first element nth-child because of thead and tbody, colgroup will be ignored */
.table-v1 > :nth-child(1 of :not(colgroup)) > tr:nth-of-type(1) > th,
.table-v1 > :nth-child(1 of :not(colgroup)) > tr:nth-of-type(1) > td {
  border-bottom-width: 1px;
  border-bottom-color: #007934;
  border-bottom-style: solid;
}

.table-v1 > :nth-child(1 of :not(colgroup)) > tr:nth-of-type(1):has(:not(tr > th)) > th {
  border-bottom-width: 1px;
  border-bottom-color: #e5e5e5;
  border-bottom-style: solid;
}

.table-v1 > :nth-child(1 of :not(colgroup)) > tr:nth-of-type(1):has(:not(tr > th)) > td {
  border-bottom-width: 1px;
  border-bottom-color: #e5e5e5;
  border-bottom-style: solid;
}

.table-v1:not(:has(thead > tr > th), :has(tbody > tr > th)) > :nth-child(1 of :not(colgroup)) > tr:nth-of-type(1) > td {
  border-bottom-width: 1px;
  border-bottom-color: #007934;
  border-bottom-style: solid;
}

/* V1 Table Column Border */
/* .table-v1 > thead > tr > th:first-child, */
/* .table-v1 > tbody > tr > td:first-child,  */
.table-v1 > tbody > tr > th:first-child {
  border-right-width: 1px;
  border-right-color: #007934;
  border-right-style: solid;
}

.table-v1 tr > th:last-child {
  border-right: none;
}

.table-v1 tr:last-child > td {
  border-bottom: none;
}

.table-v1 tr:last-child > th {
  border-bottom: none;
}

.table-v1 tr > td:last-child {
  border-right: none;
}

/* Remove border bottom if rowspan was set */
.table-v1 tr:nth-last-child(n -1) > td[rowspan="2"] {
  border-bottom: none;
}

.table-v1 tr:nth-last-child(n -2) > td[rowspan="3"] {
  border-bottom: none;
}

.table-v1 th > p {
  font-weight: 700;
  font-size: 16px;
}

.table-v1 td > p {
  font-size: 16px;
  margin: 0;
}

/* Table v2 Styles */
.table-v2 {
  border-spacing: 0;
  font-size: 16px;
}

.table-v2 th {
  border-right-width: 1px;
  border-right-color: #e5e5e5;
  border-right-style: solid;

  border-bottom-width: 1px;
  border-bottom-color: #e5e5e5;
  border-bottom-style: solid;

  font-weight: 700;
  padding: 12px;
  text-align: left;
}

.table-v2 td {
  border-right-width: 1px;
  border-right-color: #e5e5e5;
  border-right-style: solid;

  border-bottom-width: 1px;
  border-bottom-color: #e5e5e5;
  border-bottom-style: solid;

  padding: 12px;
  text-align: left;
}

.table-v2 thead > tr:first-of-type > th {
  border-bottom-width: 1px;
  border-bottom-color: #e5e5e5;
  border-bottom-style: solid;
}

.table-v2 tr > th:last-child {
  border-right: none;
}

.table-v2 tr:last-child > td {
  border-bottom: none;
}

.table-v2 tr:last-child > th {
  border-bottom: none;
}

.table-v2 tr > td:last-child {
  border-right: none;
}

.table-v2 th > p {
  font-weight: 700;
  font-size: 16px;
}

.table-v2 td > p {
  font-size: 16px;
  margin: 0;
}

/* Table v3 Styles */
.table-v3 {
  border-spacing: 0;
  font-size: 16px;
}

.table-v3 th {
  font-weight: 700;
  padding: 12px;
  text-align: left;
}

.table-v3 td {
  padding: 12px;
  text-align: left;
}

.table-v3 th > p {
  font-weight: 700;
  font-size: 16px;
}

.table-v3 td > p {
  font-size: 16px;
  margin: 0;
}

/* Table v4 Styles */
.table-v4 {
  border-spacing: 0;
  font-size: 16px;
}

.table-v4 th {
  font-weight: 700;
  padding: 12px;
  text-align: left;
  border: none;
  border-left-style: solid;
  border-left-color: #ffffff;
  border-left-width: 1;
}

.table-v4 td {
  padding: 12px;
  text-align: left;
  border: none;
  border-left-style: solid;
  border-left-color: #ffffff;
  border-left-width: 1;
}

.table-v4 > thead > tr > th {
  background-color: #f2f8f5;
}

/* Only set background color on even rows if thead is present */
.table-v4:has(thead) > tbody > tr:nth-of-type(even) {
  background-color: #f2f8f5;
}

/* Only set background color on odd rows if thead is present */
.table-v4:not(:has(thead)) > tbody > tr:nth-of-type(odd) {
  background-color: #f2f8f5;
}

.table-v4 th > p {
  font-weight: 700;
  font-size: 16px;
  margin: 0;
}

.table-v4 td > p {
  font-size: 16px;
  margin: 0;
}
